import "./index.css";
import { BrowserRouter as Router } from "react-router-dom";
import AppNavigation from "./Navigation";
import AdminContextProvider from "./store/AdminContext";
import EventsManageContextProvider from "./store/EventsManageContext";
import MediaContextProvider from "./store/MediaContext";
import OrdersContextProvider from "./store/OrdersContext";
import ProfilesContextProvider from "./store/ProfilesContext";
import AlertProvider from "./store/AlertContext";

function App() {
  return (
    <>
      <AlertProvider>
      <AdminContextProvider>
      <EventsManageContextProvider>
      <MediaContextProvider>
      <OrdersContextProvider>
      <ProfilesContextProvider>
        <Router>
          <AppNavigation/>
        </Router>
      </ProfilesContextProvider>
      </OrdersContextProvider>
      </MediaContextProvider>
      </EventsManageContextProvider>
      </AdminContextProvider>
      </AlertProvider>
    </>
  );
}

export default App;
