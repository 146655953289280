export const OrderStatusMap = {
  "0": "Started (code-0)",
  "2": "Paid (code-2)",
  "3": "Cancelled (code-3)",
  "4": "Refunded (code-4)",
  "6": "Declined (code-6)"
};

export const ResponseMsgMap = {
  "PAYMENT_PROFILE_NOT_FOUND": { type: "info", msg: "Profile not found!" },
  "PAYMENT_NOT_FOUND": { type: "info", msg: "Payment not found!" },
  "ORDER_NOT_FOUND": { type: "warning", msg: "Order not found!" },
  "PAYMENT_ACCOUNT_ALREADY_PROCESSED": { type: "info", msg: "Account already processed" },
  "REFUND_WAS_SUCCESSFUL": { type: "success" , msg: "Refund request to bank was successful!" },
  "ORDER_WAS_PROCESSED": { type: "info", msg: "Order Already Processed!" },
  "SERVER_ERROR": { type: "error", msg: "Something went wrong!" },
  "ROW_LOCKED_TOO_LONG": { type: "error", msg: "Something went wrong contact devs!" },
  "AMOUNT_MUST_BE_LESS": { type: "warning", msg: "Provided amount must be less than current deposited!" },
  "PAYMENT_WASNT_INITIATED": { type: "error", msg: "Payment wasn't initiated." },
  "PAYMENT_PENDING": { type: "info", msg: "Payment started, but not finished yet!" },
  "PAYMENT_SUCCESS": { type: "info", msg: "Payment was successful, data synchronized!" },
  "PAYMENT_VOID": { type: "info", msg: "Payment cancelled, data synchronized!" },
  "PAYMENT_REFUNDED": { type: "info", msg: "Payment refunded, data synchronized!" },
  "PAYMENT_DECLINED": { type: "error", msg: "Payment declined, data synchronized!" },
};