import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EventItem from './EventItem';
import AddEventModal from './AddEventModal';
import RemoveDialog from './RemoveDialog';
import UpdateEventModal from './UpdateEventModal';
import { EventsManageContext } from '../../store/EventsManageContext';
import { MediaContext } from '../../store/MediaContext';
import { fetchEvents, removeEvent } from '../../utils/http';
import AddProfileToEvent from './AddProfileToEvent';
import LoadingOverlay from '../LoadingOverlay';
import { AlertContext } from '../../store/AlertContext';


const EventView = () => {
    const [addModalState, setAddModalState] = useState(false);
    const [addProfileToEventModalState, setAddProfileToEventModalState] = useState(false);
    const [updateModalState, setUpdateModalState] = useState(false);
    const [removeDialodState, setRemoveDialogState] = useState(false);
    const [removeDialogContent, setRemoveDialogContent] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const { events, setEvents, addEvent, updateEvent: updateEventState, removeEvent: removeEventFromState } = useContext(EventsManageContext);
    const { documents } = useContext(MediaContext);
    const { showAlert } = useContext(AlertContext);
    const navigate = useNavigate();

    useEffect(() => {
        async function listEvents(){
            try{
                const result = await fetchEvents();
                const events = result.past.concat(result.upcoming).sort((a,b) => b.id-a.id);
                setEvents(events);
            }catch(err){
                console.log(err)
                setEvents([]);
            }
        }

        listEvents();
    },[]);

    async function openRemoveDialog(eventId, eventTitle){
        setRemoveDialogContent({ id: eventId, title: eventTitle });
        setRemoveDialogState(true);
    }

    async function removeHandler(){
        try{
            setIsLoading(true);
            const eventId = removeDialogContent.id;
            await removeEvent(eventId);
            removeEventFromState(eventId);
            setRemoveDialogContent({});
            setRemoveDialogState(false);
            showAlert('info', 'Event Removed');
        }catch(err){
            console.log(err);
            showAlert('error', err.msg);
        }finally{
            setIsLoading(false);
        }
    }

    async function openUpdateEvent(event){
        setSelectedEvent(event);
        setUpdateModalState(true);
    }

    async function openAddProfileToEventModal(event){
        setSelectedEvent(event);
        setAddProfileToEventModalState(true);
    }

    return (
        <Container>
            <Box display="flex" alignItems="center" justifyContent="space-between" marginBottom={2}>
                <Typography variant="h4" gutterBottom>Events</Typography>
                <IconButton 
                    color="primary" 
                    onClick={() => setAddModalState(true)}
                    sx={{ borderRadius: '50%' }}
                >
                    <AddCircleOutlineIcon fontSize="large" />
                </IconButton>
            </Box>
            <AddEventModal 
                isOpen={addModalState} 
                addEvent={addEvent} 
                documents={documents || []} 
                onClose={() => setAddModalState(false)}
                setOverLayState={setIsLoading}
            />
            <RemoveDialog  
                isOpen={removeDialodState} onSubmit={removeHandler} 
                title={removeDialogContent.title} 
                onClose={()=> setRemoveDialogState(false)}
                setOverLayState={setIsLoading}    
            />
            <UpdateEventModal 
                event={selectedEvent} 
                isOpen={updateModalState} 
                onClose={() => setUpdateModalState(false)}
                updateEvent={updateEventState}
                setOverLayState={setIsLoading}
            />
            <Divider/>
            <Box marginTop={5}>
                {events.map(event => (
                    <EventItem 
                        key={event.id} 
                        event={event} 
                        openRemoveDialog={() => openRemoveDialog(event.id, event.title.en)} 
                        openUpdateEvent={() => openUpdateEvent(event)}
                        openProfilesList={event.profiles && event.profiles.length 
                            ? () => navigate(`${event.id}/profiles`) 
                            : () => openAddProfileToEventModal(event)
                        }
                    />
                ))}
            </Box>
            <AddProfileToEvent 
                isOpen={addProfileToEventModalState} 
                onClose={() => setAddProfileToEventModalState(false)} 
                eventId={selectedEvent && selectedEvent.id}
                setOverLayState={setIsLoading}
            />
            <LoadingOverlay open={isLoading}/>
        </Container>
    );
};


export default EventView;