import { useContext, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import dayjs from 'dayjs';
import { cancelPayment, fetchPaymentByOrderId, refundPayment, syncOrder } from '../../utils/http';
import { AlertContext } from '../../store/AlertContext';
import { OrderStatusMap, ResponseMsgMap } from './constants';

const PaymentDetails = ({ isOpen, onClose, orderId, setOverLayState }) => {

  const [currentValues, setCurrentValues] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [refundAmount, setRefundAmount] = useState(0);
  const { showAlert } = useContext(AlertContext);

  const handleRefundAmountChange = (event) => {
    setRefundAmount(event.target.value);
  };

  const handleRefund = async() => {
    try{
      if(currentValues.bank_payment_id && refundAmount){
        setOverLayState(true);
        const response = await refundPayment(currentValues.bank_payment_id, parseFloat(refundAmount));
        setRefundAmount(0);
        const alertContent = ResponseMsgMap[response.msg] || response.msg;
        showAlert(alertContent.type || 'info', alertContent.msg);
      }
    }catch(err){
      console.log(err);
      const alertContent = ResponseMsgMap[err.msg] || err;
      showAlert(alertContent.type || 'warning', alertContent.msg);
    }finally{
      setOverLayState(false);
    }
  };

  const handleCancel = async() => {
    try{
      if(currentValues.bank_payment_id && currentValues.deposited_amount){
        setOverLayState(true);
        const response = await cancelPayment(currentValues.bank_payment_id);
        const alertContent = ResponseMsgMap[response.msg] || response.msg;
        showAlert('success', alertContent.msg || "Payment Cancellation Bank Request Was Succesful");
      }
    }catch(err){
      console.log(err);
      const alertContent = ResponseMsgMap[err.msg] || err;
      showAlert(alertContent.type || 'error', alertContent.msg);
    }finally{
      setOverLayState(false);
    }
  };

  const handleSync = async() => {
    try{
      if(currentValues.bank_payment_id && currentValues.order_id){
        setOverLayState(true);
        const response = await syncOrder(currentValues.order_id, currentValues.bank_payment_id);
        const payment = await fetchPaymentByOrderId(orderId);
        setCurrentValues(payment.data);
        const alertContent = ResponseMsgMap[response.msg] || response.msg;
        showAlert(alertContent.type || 'info', alertContent.msg);
      }
    }catch(err){
      console.log(err.msg);        
      const alertContent = ResponseMsgMap[err.msg] || err;
      showAlert(alertContent.type || 'info', alertContent.msg);
    }finally{
      setOverLayState(false);
    }
  };


  useEffect(() => {
    const fetchPayment = async () => {
      try {
        const response = await fetchPaymentByOrderId(orderId);
        setCurrentValues(response.data);
      } catch (err) {
        console.log(err);
        setCurrentValues(null);
      }
    };

    if(isOpen){
      fetchPayment();
    }
    return () => {
      setCurrentValues(null);
    };
  }, [isOpen]);

  return (
      <Modal open={isOpen} onClose={onClose}>
          <Box sx={{
              position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
              width: '90%', maxWidth: '90%', bgcolor: 'background.paper',
              p: 4, overflowY: 'auto', minHeight:'60vh', maxHeight: '60vh'
          }}>
              <IconButton
                  onClick={onClose}
                  sx={{ position: 'absolute', top: 8, right: 8 }}
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" component="h2" gutterBottom>
                  Payment Details: (ORDER - {orderId})
              </Typography>
              <Divider/>
              {currentValues && !isLoading && currentValues.order_id === orderId
              ? (
                  <Grid container spacing={2} mt={2}>
                    <Grid item xs={12}>
                        <Typography variant="body1">PaymentId: {currentValues.id}</Typography>
                    </Grid>
                    <Grid item xs={12} md={4} lg={2}>
                      <TextField
                        label="OrderID"
                        value={currentValues.order_id}
                        fullWidth
                        margin="normal"
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4} lg={2}>
                      <TextField
                        label="Order Status"
                        value={OrderStatusMap[currentValues.bank_order_status]}
                        fullWidth
                        margin="normal"
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4} lg={2}>
                        <TextField
                            label="Payment State"
                            value={currentValues.state}
                            fullWidth
                            margin="normal"
                            InputProps={{
                              readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} lg={2}>
                        <TextField
                            label="Amount"
                            value={currentValues.amount}
                            fullWidth
                            margin="normal"
                            InputProps={{
                              readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} lg={2}>
                        <TextField
                            label="Deposited Amount"
                            value={currentValues.deposited_amount}
                            fullWidth
                            margin="normal"
                            InputProps={{
                              readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} lg={2}>
                        <TextField
                            label="Refunded Amount"
                            value={currentValues.refunded_amount}
                            fullWidth
                            margin="normal"
                            InputProps={{
                              readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={12} lg={4}>
                        <TextField
                            label="Response"
                            value={currentValues.response_description}
                            fullWidth
                            margin="normal"
                            InputProps={{
                              readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={12} lg={4}>
                        <TextField
                            label="Card Holder Name"
                            value={currentValues.card_holder_name}
                            fullWidth
                            margin="normal"
                            InputProps={{
                              readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={12} lg={4}>
                      <TextField
                        label="Card Number"
                        value={currentValues.card_number}
                        fullWidth
                        margin="normal"
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <TextField
                          label="Bank Payment ID"
                          value={currentValues.bank_payment_id}
                          fullWidth
                          margin="normal"
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={2}>
                      <TextField
                        label="Refund Amount"
                        type="number"
                        value={refundAmount}
                        onChange={handleRefundAmountChange}
                        fullWidth
                        margin="normal"
                        InputProps={{
                          inputProps: { 
                            min: 0, 
                            max: currentValues.deposited_amount 
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={2}>
                      <Box mt={3}>
                        <Button
                          variant="contained"
                          color="warning"
                          onClick={handleRefund}
                          disabled={refundAmount <= 0 || refundAmount > parseFloat(currentValues.deposited_amount)}
                        >
                          Refund
                        </Button>
                      </Box>  
                    </Grid>
                    <Grid item xs={12} mt={3}>
                      <Divider/>
                    </Grid>
                    <Grid container spacing={2} justifyContent='center' mt={2}>
                      <Grid item xs={10}>
                        <Box sx={{ width: "100%", display:"flex", justifyContent: "space-between" }}>
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleSync}
                            disabled={currentValues.state === "payment_void" || currentValues.state === "payment_declined" || currentValues.deposited_amount === '0.00' && currentValues.state === 'payment_refunded'}
                          >
                            Sync
                          </Button>
                          <Button
                            variant="contained"
                            color="error"
                            onClick={handleCancel}
                            disabled={dayjs().diff(dayjs(currentValues.created_at), 'hour') > 72 || currentValues.state !== "payment_deposited"}
                          >
                            Cancel Order
                          </Button>
                        </Box>
                      </Grid>
                      
                    </Grid>
                  </Grid>
                ) 
              : null
              }
          </Box>
      </Modal>
  );
};

export default PaymentDetails;