import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Pagination from '@mui/material/Pagination';
import { ProfilesContext } from '../../store/ProfilesContext';
import { listProfiles, removeProfile } from '../../utils/http';
import dayjs from 'dayjs';
import DeleteConfirmationDialog from './DeleteConfirmationDialog';
import LoadingOverlay from '../LoadingOverlay';
import { AlertContext } from '../../store/AlertContext';

export default function ProfilesView() {
  const { profiles, setProfiles, removeProfile: removeProfileFromState } = useContext(ProfilesContext);
  const { showAlert } = useContext(AlertContext);
  const [filteredProfiles, setFilteredProfiles] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [profilesPerPage] = useState(10);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [profileIdToDelete, setProfileIdToDelete] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  

  useEffect(() => {
    const fetchProfiles = async () => {
      try {
        const result = await listProfiles();
        setProfiles(result.data);
        setFilteredProfiles(result.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchProfiles();
  }, []);

  useEffect(() => {
    const filtered = profiles.filter(profile => 
      profile.email.toLowerCase().includes(searchQuery.toLowerCase()) || 
      profile.id.toString().includes(searchQuery)
    );
    setFilteredProfiles(filtered);
    setCurrentPage(1);
  }, [searchQuery, profiles]);

  const indexOfLastProfile = currentPage * profilesPerPage;
  const indexOfFirstProfile = indexOfLastProfile - profilesPerPage;
  const currentProfiles = filteredProfiles.slice(indexOfFirstProfile, indexOfLastProfile);

  const paginate = (event, value) => {
    setCurrentPage(value);
  };

  const handleOpenDeleteDialog = (id) => {
    setProfileIdToDelete(id);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleConfirmDelete = async (id) => {
    try{
      setIsLoading(true);
      await removeProfile(id);
      removeProfileFromState(id);
      showAlert('success', 'Profile deleted');
    }catch(err){
      console.log(err);
      showAlert('error', err.msg);
    }finally{
      setIsLoading(false);
      handleCloseDeleteDialog();
    }
  };

  return (
    <Container>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
        <Typography variant="h4" gutterBottom>Profiles</Typography>
        <TextField
          label="Search by Email or ID"
          variant="outlined"
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 2 }}>
        <Pagination
          count={Math.ceil(filteredProfiles.length / profilesPerPage)}
          page={currentPage}
          onChange={paginate}
          color="primary"
          showFirstButton
          showLastButton
        />
      </Box>
      <Divider />
      <TableContainer component={Paper} sx={{ marginTop: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ color: 'primary.main' }}>ID</TableCell>
              <TableCell sx={{ color: 'primary.main' }}>First Name</TableCell>
              <TableCell sx={{ color: 'primary.main' }}>Last Name</TableCell>
              <TableCell sx={{ color: 'primary.main' }}>Email</TableCell>
              <TableCell sx={{ color: 'primary.main' }}>Phone</TableCell>
              <TableCell sx={{ color: 'primary.main' }}>Is Active</TableCell>
              <TableCell sx={{ color: 'primary.main' }}>Created At</TableCell>
              <TableCell sx={{ color: 'primary.main' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentProfiles.map((profile, index) => (
              <TableRow key={index}>
                <TableCell>{profile.id}</TableCell>
                <TableCell>{profile.first_name}</TableCell>
                <TableCell>{profile.last_name}</TableCell>
                <TableCell>{profile.email}</TableCell>
                <TableCell>{profile.phone}</TableCell>
                <TableCell>{profile.is_active ? 'Active' : 'Inactive'}</TableCell>
                <TableCell>{dayjs(profile.created_at).format('YYYY-MM-DD hh:mm A')}</TableCell>      
                <TableCell>
                  <Button 
                    variant="contained" 
                    color="primary" 
                    sx={{ marginRight: 1 }}
                    onClick={() => navigate(`${profile.id}/edit`)}
                  >
                    Edit
                  </Button>
                  <Button 
                    variant="outlined" 
                    color="error"
                    onClick={() => handleOpenDeleteDialog(profile.id)}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <DeleteConfirmationDialog
        open={deleteDialogOpen}
        onClose={handleCloseDeleteDialog}
        onConfirm={handleConfirmDelete}
        profileId={profileIdToDelete}
      />
      <LoadingOverlay open={isLoading}/>
    </Container>
  );
}
