import { useContext, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Divider from '@mui/material/Divider';
import Pagination from '@mui/material/Pagination';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { EventsManageContext } from '../../store/EventsManageContext';
import { LoadingScreen } from '../../Navigation';
import { fetchProfilesListXlsx, unRegisterProfileFromEvent } from '../../utils/http';
import UnregisterProfileDialog from './UnregisterProfileDialog';
import AddProfileToEvent from './AddProfileToEvent';
import LoadingOverlay from '../LoadingOverlay';
import { AlertContext } from '../../store/AlertContext';


export default function EventProfiles(){
  const { eventId } = useParams();
  const navigate = useNavigate();
  const [event, setEvent] = useState(null);
  const [profiles, setProfiles] = useState([]);
  const [filteredProfiles, setFilteredProfiles] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [profilesPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [profileIdToDelete, setProfileIdToDelete] = useState(null);
  const [addProfileModalState, setAddProfileModalState] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { events, updateEvent } = useContext(EventsManageContext);
  const { showAlert } = useContext(AlertContext);

  useEffect(() => {
    if(!eventId){
      navigate(-1, { replace: true });
      return;
    }

    const eventFromState = events.find((_event) => _event.id === parseInt(eventId));
    
    if(!eventFromState || !eventFromState.profiles.length){
      navigate(-1, { replace: true });
      return;
    }

    setEvent(eventFromState);
    setProfiles(eventFromState.profiles);
    setFilteredProfiles(eventFromState.profiles);
  },[events]);

  useEffect(() => {
    const filtered = profiles.filter(profile => 
      profile.email.toLowerCase().includes(searchQuery.toLowerCase()) || 
      profile.id.toString().includes(searchQuery)
    );

    setFilteredProfiles(filtered);
    setCurrentPage(1);
  }, [searchQuery, profiles]);

  const paginate = (event, value) => {
    setCurrentPage(value);
  };

  const indexOfLastProfile = currentPage * profilesPerPage;
  const indexOfFirstProfile = indexOfLastProfile - profilesPerPage;
  const currentProfiles = filteredProfiles.slice(indexOfFirstProfile, indexOfLastProfile);

  const handleOpenDeleteDialog = (id) => {
    setProfileIdToDelete(id);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleUnRegister = async (id) => {
    try {
      setIsLoading(true);
      const result = await unRegisterProfileFromEvent(eventId, { profileId: id });

      if(!result.data.profiles.length){
        navigate('/events', { replace: true });
        return;
      }

      updateEvent(result.data);
      showAlert('success', 'Profile Unregistered From Event!');
    } catch (err) {
      console.log(err);
      showAlert('error', 'Failed to unregister the profile!');
    } finally {
      handleCloseDeleteDialog();
      setIsLoading(false);
    }
  };

  const generateXlsx = async() => {
    try {
      setIsLoading(true);
      const result = await fetchProfilesListXlsx(eventId);

      const blob = new Blob([result], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'profile_list.xlsx');
      document.body.appendChild(link);
      link.click();

      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
      
      showAlert('success', 'Profiles list generated!');
    } catch (err) {
      console.log(err);
      showAlert('error', 'Failed to generate profiles list!');
    } finally {
      setIsLoading(false);
    }
  }
  

  if(!event || !profiles.length){
    return <LoadingScreen height='75vh'/>
  }

  return(
    <Container>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
        <Typography variant="h4" gutterBottom>Profiles Of The Event: ID {eventId}</Typography>
        <TextField
          label="Search by Email or ID"
          variant="outlined"
          onChange={(e) => setSearchQuery(e.target.value)}
        />        
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 2 }}>
        <Box>
          <Button color='success' variant='outlined' onClick={() => setAddProfileModalState(true)}>Register</Button>
          <Button color='secondary' variant='outlined' onClick={() => generateXlsx()} sx={{ marginLeft: 2 }}>Generate Profiles List</Button>
        </Box>
        <Pagination
          count={Math.ceil(filteredProfiles.length / profilesPerPage)}
          page={currentPage}
          onChange={paginate}
          color="primary"
          showFirstButton
          showLastButton
        />
      </Box>
      <Divider />
      <TableContainer component={Paper} sx={{ marginTop: 2 }}>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
              <TableCell style={{ fontWeight: 'bold' }}>Id</TableCell>
              <TableCell align="right" style={{ fontWeight: 'bold' }}>Name</TableCell>
              <TableCell align="right" style={{ fontWeight: 'bold' }}>Degree</TableCell>
              <TableCell align="right" style={{ fontWeight: 'bold' }}>Occupation</TableCell>
              <TableCell align="right" style={{ fontWeight: 'bold' }}>Practice</TableCell>
              <TableCell align="right" style={{ fontWeight: 'bold' }}>Email</TableCell>
              <TableCell align="right" style={{ fontWeight: 'bold' }}>Phone</TableCell>
              <TableCell align="right" style={{ fontWeight: 'bold' }}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentProfiles.map((profile) => (
              <TableRow
                key={profile.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">{profile.id}</TableCell>
                <TableCell align="right">
                  {`${profile.first_name} ${profile.last_name}`}
                </TableCell>
                <TableCell align="right">{profile.degree}</TableCell>
                <TableCell align="right">{profile.occupation_discipline}</TableCell>
                <TableCell align="right">{profile.practice_details}</TableCell>
                <TableCell align="right">{profile.email}</TableCell>
                <TableCell align="right">{profile.phone}</TableCell>
                <TableCell align="right">
                  <Button
                    variant='outlined'
                    color='error'
                    onClick={() => handleOpenDeleteDialog(profile.id)}
                  >
                    Unregister
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <UnregisterProfileDialog
        open={deleteDialogOpen}
        onClose={handleCloseDeleteDialog}
        onConfirm={handleUnRegister}
        eventTitle={event.title['en']}
        profileId={profileIdToDelete}
      />
      <AddProfileToEvent  
        eventId={eventId} 
        onClose={() => setAddProfileModalState(false)} 
        isOpen={addProfileModalState}
        setOverLayState={setIsLoading}
      />
      <LoadingOverlay open={isLoading}/>
  </Container>
  )
}