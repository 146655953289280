import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

export default function RemoveDialog({ title, onClose, isOpen, onSubmit}){
  return(
    <Dialog
        open={isOpen}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete <b>'{title}'</b> event?
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose}>No</Button>
            <Button onClick={onSubmit} autoFocus>
                Yes
            </Button>
        </DialogActions>
    </Dialog>
  )
}