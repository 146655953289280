import { useContext } from 'react';
import { NavLink, useLocation, Outlet } from 'react-router-dom';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/IconButton';
import IconButton from '@mui/material/IconButton';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import { navs } from '../navs';
import { AdminContext } from '../store/AdminContext';
import GlobalAlert from './GlobalAlert';

const drawerWidth = 240;

export default function BasementLayout(){
  const location = useLocation();
  const { signoutAdmin } = useContext(AdminContext);

  return (
    <Box sx={{ display: 'flex' }}>
      <GlobalAlert/>
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <Typography variant="h6" component="div" style={{ color: '#f5f5f5' }}>
            ArmRhinoplasty Admin
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <IconButton
            color="inherit"
            aria-label="exit"
            edge="end"
            onClick={signoutAdmin}
          >
            <ExitToAppIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box',bgcolor: '#f5f5f5' },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: 'auto' }}>
          <List>
            <ListItem disablePadding>
              <ListItemButton disableRipple disableTouchRipple selected={navs[0].to===location.pathname} component="div" style={{ width: '100%', paddingTop: 0, paddingBottom: 0 }}>
                <NavLink to={navs[0].to} style={{ display: 'block', width: '100%' }} className={({ isActive })=> isActive ? "basement-active-nav-link" : "basement-nav-links"} replace onClick={() => {}} >
                  Event
                </NavLink>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton disableRipple disableTouchRipple selected={navs[1].to===location.pathname} component="div" style={{ width: '100%', paddingTop: 0, paddingBottom: 0 }}>
                <NavLink to={navs[1].to} style={{ display: 'block', width: '100%' }} className={({ isActive })=> isActive ? "basement-active-nav-link" : "basement-nav-links"} replace onClick={() => {}} >
                  Orders
                </NavLink>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton disableRipple disableTouchRipple selected={navs[2].to===location.pathname} component="div" style={{ width: '100%', paddingTop: 0, paddingBottom: 0 }}>
                <NavLink to={navs[2].to} style={{ display: 'block', width: '100%' }} className={({ isActive })=> isActive ? "basement-active-nav-link" : "basement-nav-links"} replace onClick={() => {}} >
                  Profiles
                </NavLink>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton disableRipple disableTouchRipple selected={navs[3].to===location.pathname} component="div" style={{ width: '100%', paddingTop: 0, paddingBottom: 0 }}>
                <NavLink to={navs[3].to} style={{ display: 'block', width: '100%' }} className={({ isActive })=> isActive ? "basement-active-nav-link" : "basement-nav-links"} replace onClick={() => {}} >
                  Gallery
                </NavLink>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton disableRipple disableTouchRipple selected={navs[4].to===location.pathname} component="div" style={{ width: '100%', paddingTop: 0, paddingBottom: 0 }}>
                <NavLink to={navs[4].to} style={{ display: 'block', width: '100%' }} className={({ isActive })=> isActive ? "basement-active-nav-link" : "basement-nav-links"} replace onClick={() => {}} >
                  Documents
                </NavLink>
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Drawer>

      <Box component="main" sx={{ flexGrow: 1, p: 3, mt: 8 }}>
        <Toolbar />
        <Outlet/>
      </Box>
    </Box>
  );
};