import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import dayjs from 'dayjs';

function OrderCard({ order, selectOrder }) {

  return (
    <Card sx={{ maxWidth: 345, backgroundColor: '#fafafa', color: '#333', border: '1px solid #ccc', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }} variant='outlined'>
      <CardHeader
        title={`Order ID: ${order.id}`}
        subheader={`Status: ${order.status}`}
        sx={{ backgroundColor: '#f0f0f0' }}
      />
      <CardContent sx={{ height: '220px', overflow: 'auto' }}>
        <Typography variant='body2'>
          Profile ID: {order.profile_id}
        </Typography>
        <Typography variant='body2' mt={1}>
          Entity Type: {order.entity_type}
        </Typography>
        <Typography variant='body2' mt={1}>
          Entity ID: {order.entity_id}
        </Typography>
        <Typography variant="body2" mt={1}>
          Amount: {order.amount} AMD
        </Typography>
        {order.paid_at && (<Typography variant='body2' mt={1}>Paid: {dayjs(order.paid_at, 'DD/MM/YYYY HH:mm:ss').format("DD-MM-YYYY HH:mm:ss")}</Typography>)}
        {order.cancelled_at && (<Typography variant='body2' mt={1}>Cancelled: {dayjs(order.cancelled_at).format("DD-MM-YYYY HH:mm:ss")}</Typography>)}
        {order.refunded_at && (<Typography variant='body2' mt={1}>Refunded: {dayjs(order.refunded_at).format("DD-MM-YYYY HH:mm:ss")}</Typography>)}
        
        <Typography variant='body2' mt={1}>
          Created: {dayjs(order.created_at).format("DD-MM-YYYY HH:mm:ss")}
        </Typography>
      </CardContent>
      <Divider/>
      <CardActions sx={{ justifyContent: 'center', backgroundColor: '#f0f0f0' }}>
        <Button size="small" fullWidth onClick={() => selectOrder(order.id)} variant="text" color="primary">Payment Details</Button>
      </CardActions>
    </Card>
  );
}

export default OrderCard;
