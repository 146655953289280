import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal'

export default function ImagePreviewModal({ previewUrl, isOpen, handleClose }){

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100vh',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <img src={previewUrl} alt="Preview" style={{ width: '100%' }} />
      </Box>
    </Modal>
  )
}