import { useContext } from 'react';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import { AlertContext } from '../store/AlertContext';

const GlobalAlert = () => {
  const { alert, hideAlert } = useContext(AlertContext);

  if (!alert.open) return null;

  const TransitionUp = (props) => {
    return <Slide {...props} direction="left" />;
  };

  return (
    <Snackbar
      open={alert.open}
      autoHideDuration={5000}
      transitionDuration={{ enter: 200, exit: 200 }}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      onClose={hideAlert}
      sx={{ mt:6, mr: 1 }}
      TransitionComponent={TransitionUp}
      color='secondary'
    >
      <Alert onClose={hideAlert} severity={alert.type} variant='filled' sx={{ width: '100%' }}>
        {alert.message}
      </Alert>
    </Snackbar>
  );
};

export default GlobalAlert;