import React, { createContext, useState } from 'react';

export const OrdersContext = createContext({
  orders: [],
  setOrders: (orders) => {}
});

function OrdersContextProvider({ children }) {
  const [ordersState, setOrdersState] = useState({ orders: [] });
  
  function setOrders(orders){
    setOrdersState((prevState) => ({ ...prevState, orders }));
  }

  const value = {
    ...ordersState,
    setOrders
  };

  return (
    <OrdersContext.Provider value={value}>
      {children}
    </OrdersContext.Provider>
  );
}

export default OrdersContextProvider;
