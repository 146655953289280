import React, { createContext, useState } from 'react';

export const ProfilesContext = createContext({
  profiles: [],
  setProfiles: (profiles) => {},
  updateProfile: (profile) => {},
  removeProfile: (profileId) => {},
});

function ProfilesContextProvider({ children }) {
  const [profilesState, setProfilesState] = useState({ profiles: [] });
  
  function setProfiles(profiles){
    setProfilesState((prevState) => ({ ...prevState, profiles }));
  }

  function updateProfile(profile){
    setProfilesState((prevState) => {
      const modifiedProfiles =  prevState.profiles.map(_profile => {
        if (_profile.id === profile.id) {
          return profile;
        }
        return _profile;
      });

      return { ...prevState, profiles: modifiedProfiles };
    });
  }

  function removeProfile(profileId){
    setProfilesState((prevState) => {
      const filteredProfiles =  prevState.profiles.filter((_profile) => _profile.id !== profileId);
      return { ...prevState, profiles: filteredProfiles };
    });
  }

  const value = {
    ...profilesState,
    setProfiles,
    updateProfile,
    removeProfile
  };

  return (
    <ProfilesContext.Provider value={value}>
      {children}
    </ProfilesContext.Provider>
  );
}

export default ProfilesContextProvider;