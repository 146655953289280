import { useState } from 'react';
import { Modal, TextField, Button, Box, Typography } from '@mui/material';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '400px',
  height: 'auto',
  bgcolor: '#f5f5f5',
  boxShadow: 24,
  p: 4,
};

export default function DocumentModal({ modalState, onClose, onSave }){
    const [name, setName] = useState('');

    const handleSave = () => {
      onSave(name);
      setName('');
      onClose();
    };

    return (
      <Modal open={modalState} onClose={onClose}>
        <Box sx={modalStyle}>
          <Typography variant="h6" mb={2}>Name The Document</Typography>
          <TextField
            label="Document Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
          />
          <Button onClick={handleSave} mt={2}>Save</Button>
        </Box>
      </Modal>
    );
};
