import { useContext, useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Slider from '@mui/material/Slider';
import AvatarEditor from 'react-avatar-editor';
import { uploadImage } from '../../utils/http';
import { AlertContext } from '../../store/AlertContext';

export default function ImageUploadModal({ image, addImageToState, isOpen, handleClose, setOverLayState }){
  const [editor, setEditor] = useState(null);
  const [isImageApplied, setIsImageApplied] = useState(false);
  const [scale, setScale] = useState(1);
  const { showAlert } = useContext(AlertContext);

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: '90%', md: '800px' }, // Responsive width
    maxHeight: '90vh', // Responsive maximum height
    overflowY: 'auto', // Adds scroll
    bgcolor: '#f5f5f5',
    boxShadow: 24,
    p: 4,
    zIndex: 1000
  };
  

  const setEditorRef = (editor) => {
    setEditor(editor);
  };

  const handleScale = (e, value) => {
    setScale(value);
  };

  const onClose = () => {
    setIsImageApplied(false);
    handleClose();
  } 

  const handleApply = () => {
    if (editor) {
      const canvasScaled = editor.getImageScaledToCanvas();
      setIsImageApplied(canvasScaled);
     }
  };

  const handleSave = async() => {
    try{
      setOverLayState(true);
      const editedImage = isImageApplied.toDataURL('image/jpeg', 0.9);
      const blob = dataURLtoBlob(editedImage);
      const formData = new FormData();
      formData.append('file', blob, `event_${Date.now()}_${Math.round(Math.random() * 1000)}.jpg`);
      const result = await uploadImage(formData);
      addImageToState(result.data);
      handleClose();
      showAlert('success', 'Image Uploaded!');
    }catch(err){
      console.log(err);
      showAlert('error', 'Unabe to upload an image!');
    }finally{
      setOverLayState(false);
    }
  }

  const dataURLtoBlob = (dataURL) => {
    const arr = dataURL.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  };


  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <Grid container>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography varaint='h6' component='h2' gutterBottom>
                Edit Image
              </Typography>
              <IconButton onClick={onClose}>
                <CloseIcon/>
              </IconButton>
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ border:'1px solid blue' }}>
            {image && !isImageApplied && (
              <AvatarEditor
                ref={setEditorRef}
                image={image}
                width={700}
                height={600}
                color={[0, 0, 0, 0.6]}
                scale={scale}
                style={{ width:'100%' }}
              />
            )}
            {isImageApplied && (
              <Box sx={{ padding:2 }}>
                <img src={isImageApplied.toDataURL()} alt="Edited" width='700px' height='600px'/>
              </Box>
            )}
          </Grid>
          <Grid item mt={3} xs={12}>
            {!!isImageApplied 
              ?(
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Button variant='contained' color='secondary' onClick={() => { setIsImageApplied(false); }}>Reset</Button>
                  <Button variant='contained' onClick={handleSave}>Save</Button>
                </Box>
              ) 
              : (
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  
                  <Stack spacing={2} direction="row" sx={{ width: "200px" }} alignItems="center">
                    <ZoomOutIcon />
                      <Slider    
                        value={scale}
                        min={1}
                        max={2}
                        step={0.01}
                        onChange={handleScale}
                        style={{ width: '100px' }}
                      />
                    <ZoomInIcon />
                  </Stack>
                  <Button variant='contained' onClick={handleApply}>
                    Apply
                  </Button>
                </Box>
              )
            }
          </Grid>
        </Grid>
      </Box>
    </Modal>
  )
}