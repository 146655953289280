import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import dayjs from 'dayjs';

const EventItem = ({ event, openRemoveDialog, openUpdateEvent, openProfilesList }) => {
    const getMediaSource = () => {
        if (event.coverType === 'video' && event.coverUrl) {
            const videoId = new URLSearchParams(new URL(event.coverUrl).search).get('v');
            return `https://img.youtube.com/vi/${videoId}/0.jpg`;
        } else if (event.coverType === 'photo' && event.coverUrl) {
            return event.coverUrl;
        }
        return 'https://placehold.co/700x600';
    };
    return (
        <>
            <Card variant="outlined" sx={{ display: 'flex', marginBottom: 2 }}>
                <CardMedia
                    component="img"
                    sx={{ width: 151, objectFit: 'cover' }}
                    image={getMediaSource()}
                    alt={event.title.en}
                />
                <Grid container sx={{ flex: 1 }}>
                    <Grid item xs>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>Title: {event.title.en}</Typography>
                            <Typography color="textSecondary">Address: {event.address.en}</Typography>
                            <Typography color="textSecondary">Event Schedule: {dayjs(event.startDate).format('DD-MM-YYYY HH:mm:ss')}</Typography>
                            <Typography color="textSecondary">Created: {dayjs(event.createdAt).format('DD-MM-YYYY HH:mm:ss')}</Typography>
                            <Typography color="textSecondary">Last update: {dayjs(event.updatedAt).format('DD-MM-YYYY HH:mm:ss')}</Typography>
                        </CardContent>
                    </Grid>
                    <Grid item>
                        <Grid container direction="row" justifyContent="space-around" alignItems="flex-end" sx={{ height: "100%", width: "400px", padding: 1 }}>
                            <Button variant="contained" color="primary" sx={{ my: 1 }} onClick={openUpdateEvent}>Update</Button>
                            <Button variant="contained" color="secondary" sx={{ my: 1 }} onClick={openProfilesList}>{ !event.profiles || !event.profiles.length ? "Add Profile" : "Profiles"}</Button>
                            <Button variant="contained" color="error" sx={{ my: 1 }} onClick={openRemoveDialog}>Delete</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Card>
        </>
    );
};

export default EventItem;
