import React, { useContext, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import LibraryAddOutlinedIcon from '@mui/icons-material/LibraryAddOutlined';
import DocumentModal from './DocumentModal';
import { listDocuments, removeDocument, uploadDocument } from '../../utils/http';
import { MediaContext } from '../../store/MediaContext';
import DocumentCard from './DocumentCard';
import LoadingOverlay from '../LoadingOverlay';
import { AlertContext } from '../../store/AlertContext';


const DocumentsView = () => {
  const [documentModalState, setDocumentModalState] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDocumentFile, setSelectedDocumentFile] = useState('');
  const { documents, setDocuments, addDocument: addDocumentToStore, removeDocument: removeDocumentFromState } = useContext(MediaContext);
  const { showAlert } = useContext(AlertContext);

  useEffect(() => {
    const fetchDocuments = async() => {
      try{
        const result = await listDocuments();
        setDocuments(result.data);
      }catch(err){
        console.log(err);
      }
    }
    fetchDocuments();
  },[]);

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setSelectedDocumentFile(file);
      setDocumentModalState(true);
    }
  };

  const handleSaveDocument = async (name) => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append('file', selectedDocumentFile, `${Date.now()}_${Math.round(Math.random() * 1000)}.pdf`);
      formData.append('name', name);
      const result = await uploadDocument(formData); 
      addDocumentToStore(result.data);
      showAlert('success', 'Document uploaded!');
    } catch (err) {
      console.log(err);
      showAlert('error', 'Unabe to upload a document!');
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = async () => {
    setSelectedDocumentFile(null);
    setDocumentModalState(false);
  };

  const handleDelete = async(documentId) => {
    try{
      setIsLoading(true);
      const result = await removeDocument(documentId);
      if(result.data.url){
        removeDocumentFromState(documentId);
      }

      showAlert("info","Document was deleted!");
    } catch(err) { 
      console.log(err);
      showAlert("error","Unable to delete the document!");
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Container>
      <Box display="flex" alignItems="center" justifyContent="space-between" marginBottom={2}>
          <Typography variant="h4" gutterBottom>Documents</Typography>
          <IconButton
              color="primary"
              onClick={() => document.getElementById('file-upload-input').click()}
              sx={{ borderRadius: '50%' }}
          >
            <LibraryAddOutlinedIcon fontSize="large" />
            <input
              id="file-upload-input"
              type="file"
              hidden
              onChange={handleFileChange}
              accept=".pdf"
            />
          </IconButton>
      </Box>
      <Divider/>
      <Grid container spacing={2} mt={2}>
          {documents.map((doc, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <DocumentCard
                document={doc}
                onDelete={() => handleDelete(doc.id)}
              />
            </Grid>
          ))}
      </Grid>
      <DocumentModal 
        onSave={handleSaveDocument} 
        modalState={documentModalState} 
        onClose={handleClose}
      />
      <LoadingOverlay open={isLoading}/>
    </Container>
  );
};


export default DocumentsView;