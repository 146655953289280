import React, { useState, useRef, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';

const DocumentCard = ({ document, onDelete }) => {
  const [isCopied, setIsCopied] = useState(false);
  const timeoutRef = useRef(null);

  const handleCopyUrl = () => {
    navigator.clipboard.writeText(document.url);
    setIsCopied(true);
    timeoutRef.current = setTimeout(() => setIsCopied(false), 3000);
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const handlePreview = () => {
    window.open(document.url, '_blank');
  };

  return (
    <Card variant='outlined' sx={{ maxWidth: 345, margin: 2, backgroundColor: '#b8c9b9' }}>
      <CardContent>
        <Typography gutterBottom variant="h6" component="div">
          {document.key}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Document ID: {document.id}
        </Typography>
      </CardContent>
      <Divider/>
      <CardActions sx={{ justifyContent: 'space-around', paddingTop: 2, paddingBottom: 2 }}>
        <Button size="small" variant='contained' color='primary' onClick={handleCopyUrl}>
          {isCopied ? 'Copied!' : 'Copy URL'}
        </Button>
        <Button size="small" variant='contained' color='warning' onClick={handlePreview}>
          Preview
        </Button>
        <Button size="small" variant='contained' color='error' onClick={onDelete}>
          Delete
        </Button>
      </CardActions>
    </Card>
  );
};

export default DocumentCard;
