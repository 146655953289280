import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CloseIcon from '@mui/icons-material/Close';
import EventCard from './EventCard';
import { useTranslation } from 'react-i18next';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function PreviewModal({ previewOpen, event, onClose }) {
  const [value, setValue] = useState(0);
  const { i18n } = useTranslation();
  const defaultLang = localStorage.getItem('i18nextLng') || 'en';
  const langs = ['en', 'ru', 'am'];

  const { coverType, coverUrl, title, description, startDate, endDate, address } = event;
  
  const handleChange = (event, newValue) => {
    i18n.changeLanguage(langs[newValue]);
    setValue(newValue);
  };

  useEffect(() => {
    i18n.changeLanguage(langs[0]);
    return () => i18n.changeLanguage(defaultLang);
  },[]);


  return (
    <Modal open={previewOpen} onClose={onClose}>
      <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '100vw', maxWidth: '1200px', bgcolor: '#f5f5f5', p: 4 }}>
        <IconButton
          onClick={onClose}
          sx={{ position: 'absolute', top: 10, right: 10 }}
        >
          <CloseIcon />
        </IconButton>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            {langs.map((lng, i) => (
              <Tab key={i} label={lng.toUpperCase()} {...a11yProps(i)} />
            ))}
          </Tabs>
        </Box>
        <Box sx={{ width: '100%' }}>
          {Array.from({ length: 3 }, (_, i) => (
            <EventPanel key={i} value={value} index={i}>
              <EventCard 
                isPassed={false} 
                startDate={startDate}
                endDate={endDate}
                coverType={coverType}
                coverUrl={coverUrl}
                title={title[langs[i]]}
                description={description[langs[i]]}
                address={address[langs[i]]}  
                disabled={false}
                onButtonClick={() => {}}
              />
            </EventPanel>
          ))}
        </Box>
      </Box>
    </Modal>
  );
}

function EventPanel({ children, value, index, ...other }) {

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}