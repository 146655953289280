import { useContext, useState } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import Divider from '@mui/material/Divider';
import PreviewModal from './PreviewModal';
import { TextareaAutosize } from '@mui/base';
import { Formik, Form, FieldArray } from 'formik';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { updateEvent } from '../../utils/http';
import dayjs from 'dayjs';
import * as Yup from 'yup';
import { AlertContext } from '../../store/AlertContext';


const validationSchema = Yup.object({
    title: Yup.object({
        en: Yup.string().required('Required'),
        ru: Yup.string().required('Required'),
        am: Yup.string().required('Required'),
    }),
    address: Yup.object({
        en: Yup.string().required('Required'),
        ru: Yup.string().required('Required'),
        am: Yup.string().required('Required'),
    }),
    description: Yup.object({
        en: Yup.string().required('Required'),
        ru: Yup.string().required('Required'),
        am: Yup.string().required('Required'),
    }),
    startDate: Yup.date().required('Required'),
    endDate: Yup.date().notRequired(),
    coverType: Yup.string().required('Required'),
    coverUrl: Yup.string().url('Must be a valid URL').required('Required'),
    notify_about_conf_url: Yup.bool().notRequired(),
    online_conference_url: Yup.string().notRequired(),
    document_url: Yup.string().notRequired(),
    pricePeriodPhysician: Yup.array().of(
        Yup.object().shape({
            daysBefore: Yup.number().required('Required'),
            price: Yup.number().required('Required')
        })
    ).min(1, 'At least one pricing period is required for physicians'),
    pricePeriodResident: Yup.array().of(
        Yup.object().shape({
            daysBefore: Yup.number().required('Required'),
            price: Yup.number().required('Required')
        })
    ).min(1, 'At least one pricing period is required for resident'),
});

const UpdateEventModal = ({ isOpen, onClose, updateEvent: updateEventState, event, setOverLayState }) => {
    const [previewOpen, setPreviewOpen] = useState(false);
    const [currentValues, setCurrentValues] = useState(null);
    const { showAlert } = useContext(AlertContext);

    const handleSubmit = async (values) => {
        try {
            setOverLayState(true);
            const result = await updateEvent(values.id, { version: event.version, ...values });
            updateEventState(result.data);
            onClose();
            showAlert('success', 'Event Updated!');
        } catch (err) {
            console.log(err);
            showAlert('error', err.msg || 'Something went wrong!');
        } finally {
            setOverLayState(false);
        }
    };

    return (
        <Modal open={isOpen} onClose={onClose}>
            <Box sx={{
                position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
                width: '80vw', maxWidth: '800px', bgcolor: 'background.paper',
                p: 4, overflowY: 'auto', maxHeight: '90vh'
            }}>
                <IconButton
                    onClick={onClose}
                    sx={{ position: 'absolute', top: 8, right: 8 }}
                >
                    <CloseIcon />
                </IconButton>
                <Typography variant="h6" component="h2" gutterBottom>
                    Update Event
                </Typography>
                <Formik initialValues={{ ...event, online_conference_url: (event && event.online_conference_url) || '', notify_about_conf_url: false, document_url: (event && event.document_url) || '' }} validationSchema={validationSchema} onSubmit={handleSubmit}>
                    {({ values, setFieldValue, isValid, dirty, isSubmitting }) => {
                        const handlePreview = () => {
                            setCurrentValues(values);
                            setPreviewOpen(true);
                        };

                        return (
                            <Form id='UpdateModalForm'>
                                <Grid container spacing={2}>
                                    {Object.keys(values.title).map(lang => (
                                        <Grid item xs={12} md={4} key={lang}>
                                            <TextField
                                                label={`Title (${lang.toUpperCase()})`}
                                                name={`title.${lang}`}
                                                value={values.title[lang]}
                                                onChange={(e) => setFieldValue(`title.${lang}`, e.target.value)}
                                                required
                                                fullWidth
                                            />
                                        </Grid>
                                    ))}

                                    {Object.keys(values.description).map(lang => (
                                        <Grid item xs={12} key={lang}>
                                            <Typography variant="subtitle1" gutterBottom>
                                                Description ({lang.toUpperCase()})
                                            </Typography>
                                            <TextareaAutosize
                                                minRows={3}
                                                name={`description.${lang}`}
                                                value={values.description[lang]}
                                                onChange={(e) => setFieldValue(`description.${lang}`, e.target.value)}
                                                style={{ width: '100%', padding: "15px", borderRadius: "4px", color: 'rgba(0,0,0,0.87)', fontSize: '1rem', lineHeight: '1.4375em', alignItems: 'center' }}
                                            />
                                        </Grid>
                                    ))}

                                    {Object.keys(values.address).map(lang => (
                                        <Grid item xs={12} md={4} key={lang}>
                                            <TextField
                                                label={`Address (${lang.toUpperCase()})`}
                                                name={`address.${lang}`}
                                                value={values.address[lang]}
                                                onChange={(e) => setFieldValue(`address.${lang}`, e.target.value)}
                                                required
                                                fullWidth
                                            />
                                        </Grid>
                                    ))}

                                    <Grid item xs={12} md={6}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DateTimePicker
                                                sx={{ width: '100%' }}
                                                label="Event Start Date*"
                                                name="startDate"
                                                format='DD/MM/YYYY hh:mm A'
                                                value={values.startDate ? dayjs(values.startDate) : null}
                                                onChange={(value) => setFieldValue('startDate', value ? value : null)}
                                                fullWidth
                                                required
                                            />
                                        </LocalizationProvider>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DateTimePicker
                                                sx={{ width: '100%' }}
                                                label="Event End Date (Optional)"
                                                name="endDate"
                                                format='DD/MM/YYYY hh:mm A'
                                                value={values.endDate ? dayjs(values.endDate) : null}
                                                onChange={(value) => setFieldValue('endDate', value ? value : null)}
                                                fullWidth
                                                required
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={12} sm={7}>
                                        <TextField
                                            label="Document URL (Optional)"
                                            name="document_url"
                                            value={values.document_url}
                                            onChange={(e) => setFieldValue('document_url', e.target.value)}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="body1" component='span'>Price Periods for Physicians</Typography>
                                        <Tooltip
                                            title="All period days are calculated from the event start date."
                                            sx={{
                                                typography: 'body2',
                                                '& .MuiTooltip-tooltip': {
                                                    fontSize: '2rem',
                                                }
                                            }}
                                        >
                                            <IconButton>
                                                <InfoIcon fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                        <FieldArray name="pricePeriodPhysician">
                                            {({ push, remove, form }) => (
                                                <Box>
                                                    {form.values.pricePeriodPhysician.map((_, index) => (
                                                        <Grid container columnSpacing={2} key={index} mt={1}>
                                                            <Grid item xs={5}>
                                                                <TextField
                                                                    label={`Days Before (Physician)`}
                                                                    name={`pricePeriodPhysician.${index}.daysBefore`}
                                                                    type="number"
                                                                    value={form.values.pricePeriodPhysician[index].daysBefore}
                                                                    onChange={form.handleChange}
                                                                    fullWidth
                                                                />
                                                            </Grid>
                                                            <Grid item xs={5}>
                                                                <TextField
                                                                    label={`Price (AMD)`}
                                                                    name={`pricePeriodPhysician.${index}.price`}
                                                                    type="number"
                                                                    value={form.values.pricePeriodPhysician[index].price}
                                                                    onChange={form.handleChange}
                                                                    fullWidth
                                                                />
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <IconButton onClick={() => remove(index)}>
                                                                    <CloseIcon />
                                                                </IconButton>
                                                            </Grid>
                                                        </Grid>
                                                    ))}
                                                    <Button onClick={() => push({ daysBefore: '', price: '' })}>Add Period</Button>
                                                </Box>
                                            )}
                                        </FieldArray>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Typography variant="body1" component='span'>Price Periods for Residents</Typography>
                                        <Tooltip
                                            title="All period days are calculated from the event start date."
                                            sx={{
                                                typography: 'body2',
                                                '& .MuiTooltip-tooltip': {
                                                    fontSize: '1rem',
                                                }
                                            }}
                                        >
                                            <IconButton>
                                                <InfoIcon fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                        <FieldArray name="pricePeriodResident">
                                            {({ push, remove, form }) => (
                                                <Box>
                                                    {form.values.pricePeriodResident.map((_, index) => (
                                                        <Grid container columnSpacing={2} key={index} mt={1}>
                                                            <Grid item xs={5}>
                                                                <TextField
                                                                    label={`Days Before (Resident)`}
                                                                    name={`pricePeriodResident.${index}.daysBefore`}
                                                                    type="number"
                                                                    value={form.values.pricePeriodResident[index].daysBefore}
                                                                    onChange={form.handleChange}
                                                                    fullWidth
                                                                />
                                                            </Grid>
                                                            <Grid item xs={5}>
                                                                <TextField
                                                                    label={`Price (AMD)`}
                                                                    name={`pricePeriodResident.${index}.price`}
                                                                    type="number"
                                                                    value={form.values.pricePeriodResident[index].price}
                                                                    onChange={form.handleChange}
                                                                    fullWidth
                                                                />
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <IconButton onClick={() => remove(index)}>
                                                                    <CloseIcon />
                                                                </IconButton>
                                                            </Grid>
                                                        </Grid>
                                                    ))}
                                                    <Button onClick={() => push({ daysBefore: '', price: '' })}>Add Period</Button>
                                                </Box>
                                            )}
                                        </FieldArray>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>

                                    <Grid item xs={12} md={4}>
                                        <FormControl fullWidth>
                                            <InputLabel>Cover Type</InputLabel>
                                            <Select
                                                name="coverType"
                                                value={values.coverType}
                                                onChange={(e) => setFieldValue('coverType', e.target.value)}
                                                required
                                            >
                                                <MenuItem value="photo">Photo</MenuItem>
                                                <MenuItem value="video">Video</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} md={8}>
                                        <TextField
                                            label="Cover URL"
                                            name="coverUrl"
                                            value={values.coverUrl}
                                            onChange={(e) => setFieldValue('coverUrl', e.target.value)}
                                            required
                                            fullWidth
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={7}>
                                        <TextField
                                            label="Conference URL"
                                            name="online_conference_url"
                                            value={values.online_conference_url}
                                            onChange={(e) => setFieldValue('online_conference_url', e.target.value)}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={5} mt={1}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    disabled={!values.online_conference_url}
                                                    name="notify_about_conf_url"
                                                    checked={values.notify_about_conf_url}
                                                    onChange={(e) => setFieldValue('notify_about_conf_url', e.target.checked)}
                                                />
                                            }
                                            label="Notify about conference URL"
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                disabled={!isValid || !dirty || isSubmitting}
                                            >
                                                Save
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color='warning'
                                                onClick={handlePreview}
                                                disabled={!isValid || isSubmitting}
                                            >
                                                Preview
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Form>
                        );
                    }}
                </Formik>

                {currentValues && (
                    <PreviewModal
                        previewOpen={previewOpen}
                        event={currentValues}
                        onClose={() => setPreviewOpen(false)}
                    />
                )}
            </Box>
        </Modal>
    );
};

export default UpdateEventModal;