import React, { createContext, useState } from 'react';

export const AlertContext = createContext();

export default function AlertProvider({ children }){
  const [alert, setAlert] = useState({ open: false, type: 'info', message: '' });

  const showAlert = (type, message) => {
    setAlert({ open: true, type, message });
  };

  const hideAlert = () => {
    setAlert({ ...alert, open: false });
  };

  return (
    <AlertContext.Provider value={{ alert, showAlert, hideAlert }}>
      {children}
    </AlertContext.Provider>
  );
};
