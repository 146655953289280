import { useContext, useEffect, useState } from 'react';
import { Navigate, Routes, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import LoginForm from './views/LoginForm';
import EventManage from './views/Events/Events';
import EventProfilesManage from './views/Events/EventProfiles';
import ProfilesManage from './views/Profiles/Profiles';
import ProfileEditManage from './views/Profiles/ProfileEdit';
import BasementLayout from './views/Basement';
import GalleryManage from './views/Gallery/Gallery';
import DocumentsManage from './views/Documents/Documents';
import OrdersManage from './views/Orders/Orders';
import { AdminContext } from './store/AdminContext';
import { checkToken } from './utils/http';
import { eventEmitter } from './utils/eventEmitter';
import { AlertContext } from './store/AlertContext';
import GlobalAlert from './views/GlobalAlert';

export default function AppNavigation() {
  const [isAdminLoading, setIsAdminLoading] = useState(true);
  const { isAuthenticated: isAdminAuth, authenticateAdmin, signoutAdmin } = useContext(AdminContext);
  const { showAlert } = useContext(AlertContext);
  const { ready } = useTranslation();

  useEffect(() => {
    const adminToken = localStorage.getItem('admin_token');

    async function checkAuthState(token){
      try{
        const { id } = await checkToken(token);
        authenticateAdmin({ id, token })
      }catch(err){
        console.log(err)
        signoutAdmin();
      }finally{
        setIsAdminLoading(false);
      }
    }

    if(adminToken){
      checkAuthState(adminToken, true);
    } else {
      setIsAdminLoading(false);
    }

    function handleUnauthorized(){
      signoutAdmin();
      showAlert('error', 'Session Expired!');
    }

    eventEmitter.on('unauthorized', handleUnauthorized);

    return () => {
      eventEmitter.removeListener('unauthorized', handleUnauthorized);
    };
  },[]);


  if(!ready || isAdminLoading){
    return <LoadingScreen height='100vh'/>;
  }

  return (
    <Routes>
      <Route path='' element={ isAdminAuth ? <BasementLayout/> : <LoginForm/>}>
        {isAdminAuth && <Route index element={<Navigate to='/events' replace/>}/>}
        {isAdminAuth && <Route path='events' element={<EventManage/>}/>}
        {isAdminAuth && <Route path='events/:eventId/profiles' element={<EventProfilesManage/>}/>}
        {isAdminAuth && <Route path='orders' element={<OrdersManage/>}/>}
        {isAdminAuth && <Route path='profiles' element={<ProfilesManage/>}/>}
        {isAdminAuth && <Route path='profiles/:profileId/edit' element={<ProfileEditManage/>}/>}
        {isAdminAuth && <Route path='gallery' element={<GalleryManage/>}/>}
        {isAdminAuth && <Route path='documents' element={<DocumentsManage/>}/>}
        <Route path='*' element={<Navigate to='/basement' replace/>}/>
      </Route>
      <Route path='*' element={<Navigate to='/events' replace/>}/>
    </Routes>
  );
}

export const LoadingScreen = ({ height }) => (
  <Box sx={{ 
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center', 
    height
  }}>
    <CircularProgress size={55} />
  </Box>
)