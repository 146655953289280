import { useState, useEffect } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Pagination from '@mui/material/Pagination';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { ordersFetch } from '../../utils/http';
import OrderCard from './OrderCard';
import PaymentDetails from './PaymentDetails';
import LoadingOverlay from '../LoadingOverlay';


export default function OrdersView(){
  const [currentPage, setCurrentPage] = useState(1);
  const [orders, setOrders] = useState([]);
  const [statusFilter, setStatusFilter] = useState('None');
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [paymentModalState, setPaymentModalState] = useState(false);

  const ORDERS_PER_PAGE = 9; 

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const filter = { 
          page: currentPage,
          size: ORDERS_PER_PAGE,
          query: searchQuery,
          status: statusFilter
        };
        const response = await ordersFetch(filter);
        setOrders(response.data.orders); 
        setTotalPages(Math.ceil(response.data.count / ORDERS_PER_PAGE));
      } catch (err) {
        console.log(err);
      }
    };

    fetchOrders();
  }, [searchQuery, statusFilter, currentPage]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const selectOrderId = (id) => {
    setSelectedOrderId(id);
    setPaymentModalState(true)
  }

  const closePaymnetModal = () => {
    setPaymentModalState(false);
  }

  return (
    <Container>
       <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
        <Typography variant="h4" gutterBottom>Orders</Typography>
        <TextField
          label="Search by Profile ID"
          variant="outlined"
          type='number'
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 2 }}>
        <Box>
          <Select 
            value={statusFilter} 
            onChange={(e) => {
              setStatusFilter(e.target.value);
              setCurrentPage(1);
            }}
          >
            <MenuItem value={'None'}>None</MenuItem>
            <MenuItem value={'Paid'}>Paid</MenuItem>
            <MenuItem value={'Pending'}>Pending</MenuItem>
            <MenuItem value={'Declined'}>Declined</MenuItem>
            <MenuItem value={'Cancelled'}>Cancelled</MenuItem>
            <MenuItem value={'Refunded'}>Refunded</MenuItem>
          </Select>
        </Box>
        <Pagination
          count={totalPages}
          page={currentPage}
          size="large"
          onChange={handlePageChange}
        />
      </Box>
      <Divider/>
      <Grid container spacing={2} mt={2}>
        {orders.length
          ? orders.map((order, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <OrderCard order={order} selectOrder={selectOrderId} />
            </Grid>
          ))
          : null
        }
      </Grid>
      <PaymentDetails 
        onClose={closePaymnetModal}
        isOpen={paymentModalState}  
        orderId={selectedOrderId}
        setOverLayState={setIsLoading}
      />
      <LoadingOverlay open={isLoading}/>
    </Container>
  )
}