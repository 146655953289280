import React, { createContext, useState } from 'react';

export const EventsManageContext = createContext({
  events: [],
  setEvents: (events) => {},
  addEvent:(event) => {},
  updateEvent:(event) => {},
  removeProfileFromEvent:(eventId, profileId) => {},
  removeEvent:(eventId) => {}
});


function EventsManageContextProvider({ children }) {
  const [eventState, setEventState] = useState([]);
  
  function setEvents(events){
    setEventState(events);
  }

  function addEvent(event){
    setEventState((prevState) => {
      return [event, ...prevState];
    });
  }

  function updateEvent(event) {
    setEventState((prevState) => {
      return prevState.map(item => {
        if (item.id === event.id) {
          return event;
        }
        return item;
      });
    });
  }

  function addProfileToEvent(eventId, profile) {
    setEventState((prevState) => {
      return prevState.map(item => {
        if (item.id === eventId) {
          item.profiles.push(profile);
        }
        return item;
      });
    });
  }

  function removeProfileFromEvent(eventId, profileId) {
    setEventState((prevState) => {
      return prevState.map((_event) => {
        if(_event.id === eventId){
          const profiles = _event.profiles.filter((_profile) => _profile.id !== profileId);
          _event.profiles = profiles;
        }
        return _event;
      })
    });
  }

  function removeEvent(eventId){
    setEventState((prevState) => {
      return prevState.filter(item => item.id !== eventId);
    })
  }

  const value = {
    events: eventState,
    setEvents,
    addEvent,
    updateEvent,
    removeProfileFromEvent,
    removeEvent
  };

  return (
    <EventsManageContext.Provider value={value}>
      {children}
    </EventsManageContext.Provider>
  );
}

export default EventsManageContextProvider;
