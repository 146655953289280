import axiosInstance from './axiosInstance';
import { eventEmitter } from './eventEmitter';

axiosInstance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response) {
      if(error.response.status === 401){
        error.response.data.msg = 'Session Expired';
        eventEmitter.emit('unauthorized');
      }

      error.msg = error.response ? error.response.data.msg : error.message;
    } else if (error.request) {
      console.error("No response received:", error.request);
    } else {
      console.error("Error setting up request:", error.message);
    }
    return Promise.reject(error);
  }
);

const BACKEND_URL = 'https://api.ars.am';
// const BACKEND_URL = 'http://localhost:4000';

export async function adminSignIn(data){
  try{
    const response = await axiosInstance.post(`${BACKEND_URL}/account/basement/token`, data);
    return response.data;
  }catch(err){
    console.log(err);
    err.msg = err.response.data.msg || err.message; 
    throw err;
  }
}

export async function checkToken(token){
  try{
    const response = await axiosInstance.get(`${BACKEND_URL}/account/checkToken?token=Bearer ${token}`);
    return response.data;
  }catch(err){
    console.log(err);
    throw err;
  }
}

export async function fetchEvents(type, page, limit) {
  try{
    const queries = new URLSearchParams({ type, page, limit }).toString();
    const response = await axiosInstance.get(`${BACKEND_URL}/events?${queries}`);
    return response.data.events;
  }catch(err){
    console.log(err);
    throw err;
  }
}

export async function createEvent(data){
  try{
    const response = await axiosInstance.post(`${BACKEND_URL}/events/`, { ...data }, { headers: { 'Authorization': `Bearer ${localStorage.getItem('admin_token')}` } });
    return response.data;
  }catch(err){
    console.log(err);
    throw err;
  }
}

export async function updateEvent(id,data){
  try{
    const response = await axiosInstance.patch(`${BACKEND_URL}/events/${id}`, { ...data }, { headers: { 'Authorization': `Bearer ${localStorage.getItem('admin_token')}` } });
    return response.data;
  }catch(err){
    console.log(err);
    throw err;
  }
}

export async function removeEvent(eventId){
  try{
    const response = await axiosInstance.delete(`${BACKEND_URL}/events/${eventId}`, { headers: { 'Authorization': `Bearer ${localStorage.getItem('admin_token')}` } });
    return response.data;
  }catch(err){
    console.log(err);
    throw err;
  }
}

export async function registerProfileForEvent(id, data){
  try{
    const response = await axiosInstance.patch(`${BACKEND_URL}/events/${id}/register`, data , { headers: { 'Authorization': `Bearer ${localStorage.getItem('admin_token')}` } });
    return response.data;
  }catch(err){
    console.log(err);
    throw err;
  }
}

export async function unRegisterProfileFromEvent(id, data){
  try{
    const response = await axiosInstance.patch(`${BACKEND_URL}/events/${id}/unregister`, data , { headers: { 'Authorization': `Bearer ${localStorage.getItem('admin_token')}` } });

    return response.data;
  }catch(err){
    console.log(err);
    throw err;
  }
}

export async function fetchProfilesListXlsx(id){
  try{
    const response = await axiosInstance.get(`${BACKEND_URL}/events/${id}/fetchXlsx`, { headers: { 'Authorization': `Bearer ${localStorage.getItem('admin_token')}` }, responseType: 'blob' });
    return response.data;
  }catch(err){
    console.log(err);
    throw err;
  }
}

export async function listImages(purpose){
  try{
    const response = await axiosInstance.get(`${BACKEND_URL}/media?purpose=${purpose}`, { headers: { 'Authorization': `Bearer ${localStorage.getItem('admin_token')}`}});
    return response.data;
  }catch(err){
    console.log(err);
    throw err;
  }
}

export async function uploadImage(data){
  try{
    const response = await axiosInstance.post(`${BACKEND_URL}/media?purpose=Event`,data, { headers: { 'Authorization': `Bearer ${localStorage.getItem('admin_token')}` } });
    return response.data;
  }catch(err){
    console.log(err);
    throw err;
  }
}

export async function removeImage(imageId){
  try{
    const response = await axiosInstance.delete(`${BACKEND_URL}/media/${imageId}`, { headers: { 'Authorization': `Bearer ${localStorage.getItem('admin_token')}` } });
    return response.data;
  }catch(err){
    console.log(err);
    throw err;
  }
}

export async function listDocuments(){
  try{
    const response = await axiosInstance.get(`${BACKEND_URL}/media?purpose=Document`, { headers: { 'Authorization': `Bearer ${localStorage.getItem('admin_token')}`}});
    return response.data;
  }catch(err){
    console.log(err);
    throw err;
  }
}

export async function uploadDocument(data){
  try{
    const response = await axiosInstance.post(`${BACKEND_URL}/media?purpose=Document`,data, { headers: { 'Authorization': `Bearer ${localStorage.getItem('admin_token')}` } });
    return response.data;
  }catch(err){
    console.log(err);
    throw err;
  }
}

export async function removeDocument(docId){
  try{
    const response = await axiosInstance.delete(`${BACKEND_URL}/media/${docId}`,{ headers: { 'Authorization': `Bearer ${localStorage.getItem('admin_token')}` } });
    return response.data;
  }catch(err){
    console.log(err);
    throw err;
  }
}

export async function ordersFetch(filter){
  try{
    let url = `${BACKEND_URL}/orders?`;
    Object.entries(filter).forEach(([key, value]) => {
      if(value && value != 'None'){
        url += `${key}=${value}&`;
      }
    });
    url = url.slice(0, -1);
    const response = await axiosInstance.get(url, { headers: { 'Authorization': `Bearer ${localStorage.getItem('admin_token')}` } });
    return response.data;
  }catch(err){
    console.log(err);
    throw err;
  }
}

export async function listProfiles(){
  try{
    const response = await axiosInstance.get(`${BACKEND_URL}/profile/list`, { headers: { 'Authorization': `Bearer ${localStorage.getItem('admin_token')}` } });
    return response.data;
  }catch(err){
    console.log(err);
    throw err;
  }
}

export async function fetchProfile(profileId){
  try{
    const response = await axiosInstance.get(`${BACKEND_URL}/profile/${profileId}`);
    return response.data;
  }catch(err){
    console.log(err);
    throw err;
  }
}

export async function updateProfile(profileId, data){
  try{
    const response = await axiosInstance.patch(`${BACKEND_URL}/profile/${profileId}`, data, { headers: { 'Authorization': `Bearer ${localStorage.getItem('admin_token')}` } });
    return response.data;
  }catch(err){
    console.log(err);
    throw err;
  }
}

export async function removeProfile(profileId){
  try{
    const response = await axiosInstance.delete(`${BACKEND_URL}/profile/${profileId}`, { headers: { 'Authorization': `Bearer ${localStorage.getItem('admin_token')}` } });
    return response.data;
  }catch(err){
    console.log(err);
    throw err;
  }
}

export async function fetchPaymentByOrderId(orderId){
  try{
    const response = await axiosInstance.get(`${BACKEND_URL}/payment/order/${orderId}`, { headers: { 'Authorization': `Bearer ${localStorage.getItem('admin_token')}` } });
    return response.data;
  }catch(err){
    console.log(err);
    throw err;
  }
}

export async function refundPayment(paymentId, amount){
  try{
    const response = await axiosInstance.post(`${BACKEND_URL}/payment/refund`,{ paymentId, amount }, { headers: { 'Authorization': `Bearer ${localStorage.getItem('admin_token')}` } });
    return response.data;
  }catch(err){
    console.log(err);
    throw err;
  }
}

export async function cancelPayment(paymentId){
  try{
    const response = await axiosInstance.post(`${BACKEND_URL}/payment/cancel`,{ paymentId }, { headers: { 'Authorization': `Bearer ${localStorage.getItem('admin_token')}` } });
    return response.data;
  }catch(err){
    console.log(err);
    throw err;
  }
}

export async function syncOrder(orderId, paymentId){
  try{
    const response = await axiosInstance.post(`${BACKEND_URL}/payment/check`,{ orderId, paymentId }, { headers: { 'Authorization': `Bearer ${localStorage.getItem('admin_token')}` } });
    return response.data;
  }catch(err){
    console.log(err);
    throw err;
  }
}