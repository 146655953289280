import { useContext, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { MuiTelInput } from 'mui-tel-input';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { LoadingScreen } from '../../Navigation';
import { updateProfile } from '../../utils/http';
import { countryNames } from '../../utils/countriesData';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import { ProfilesContext } from '../../store/ProfilesContext';
import LoadingOverlay from '../LoadingOverlay';
import { AlertContext } from '../../store/AlertContext';


const validationSchema = Yup.object({
  first_name: Yup.string().required('Required'),
  last_name: Yup.string().required('Required'),
  email: Yup.string().matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, 'Email invalid!').required('Required'),
  birth_date: Yup.date().required('Required'),
  phone: Yup.string().required('Required'),
  business_address: Yup.string().required('Required'),
  occupation_discipline: Yup.string().required('Required'),
  practice_details: Yup.string().oneOf(['physician', 'resident'], 'Invalid Degree').required('Required'),
  degree: Yup.string().oneOf(['bachelor', 'master', 'doctorate', 'professorship'], 'Invalid Degree').required('Required'),
  country: Yup.string().required('Required'),
  is_active: Yup.boolean().required('Required'),
  terms_accepted: Yup.boolean().required('Required'),
});


export default function ProfileEdit() { 
  const { profileId } = useParams();
  const navigate = useNavigate();
  const [profile, setProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { profiles, updateProfile: updateProfilesState } = useContext(ProfilesContext);
  const { showAlert } = useContext(AlertContext);

  useEffect(() => {
    async function getProfile() {
      try {
        if (!profileId) {
          navigate('/profiles', { replace: true });
          return;
        }

        const data = profiles.find((_profile) => _profile.id === parseInt(profileId));

        if (!data) {
          navigate('/profiles', { replace: true });
          return;
        }

        setProfile(data);
      } catch (err) {
        console.log(err);
        navigate('/profiles', { replace: true });
      }
    }

    getProfile();
  }, [profileId, navigate]);

  const handleSubmit = async(values) => {
    try{
      setIsLoading(true);
      const trimmedValues = Object.keys(values).reduce((acc, key) => {
        acc[key] = typeof values[key] === 'string' ? values[key].trim() : values[key];
        return acc;
      }, {});

      const response = await updateProfile(profileId, trimmedValues);
      setProfile(response.data);
      updateProfilesState(response.data);
      showAlert('success', 'Profile Updated!');
    }catch(err){
      console.log(err);
      showAlert('error', err.msg);
    }finally{
      setIsLoading(false);
    }
  }

  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
      birth_date: '',
      country: '',
      degree: '',
      occupation_discipline: '',
      business_address: '',
      phone: '',
      practice_details: '',
      is_active: false,
      terms_accepted: false
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmit
  });
  

  useEffect(() => {
    if (profile) {
      formik.setValues(profile);
    }
  }, [profile, formik.setValues]);
  

  if (!profile) {
    return <LoadingScreen height="75vh" />;
  }
  
  return (
    <Container>
      <Box component="form" onSubmit={formik.handleSubmit} sx={{ mt: 4 }}>
      <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', mb: 2 }}>
        <Typography variant="h4" gutterBottom>Edit Profile: ID {profile.id}</Typography>
      </Box>
      <Box mb={5}>
        <Divider/>
      </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              fullWidth
              id="first_name"
              name="first_name"
              label="First Name"
              value={formik.values.first_name}
              onChange={formik.handleChange}
              error={formik.touched.first_name && Boolean(formik.errors.first_name)}
              helperText={formik.touched.first_name && formik.errors.first_name}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              fullWidth
              id="last_name"
              name="last_name"
              label="Lirst Name"
              value={formik.values.last_name}
              onChange={formik.handleChange}
              error={formik.touched.last_name && Boolean(formik.errors.last_name)}
              helperText={formik.touched.last_name && formik.errors.last_name}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              fullWidth
              id="email"
              name="email"
              label="Email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <FormControl fullWidth>
              <InputLabel id="country-label">Country</InputLabel>
              <Select
                labelId="country-label"
                id="country"
                name="country"
                value={formik.values.country}
                label="Country"
                onChange={formik.handleChange}
              >
                {countryNames.map((country) => (
                  <MenuItem key={country} value={country}>
                    {country}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                sx={{ width: '100%' }}
                format='DD/MM/YYYY'
                label="Birth Date"
                name="birth_date"
                value={formik.values.birth_date ? dayjs(formik.values.birth_date) : null}
                onChange={(value) => formik.setFieldValue('birth_date', value ? value : null)}
                fullWidth
                required
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MuiTelInput 
              fullWidth
              value={formik.values.phone}
              label='Phone'
              onChange={(value) => formik.setFieldValue('phone', value)}
              defaultCountry='AM'
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              helperText={formik.touched.phone && formik.errors.phone}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <FormControl fullWidth>
              <InputLabel id="degree-label">Degree</InputLabel>
              <Select
                labelId="degree-label"
                id="degree"
                name="degree"
                value={formik.values.degree}
                label="Degree"
                onChange={formik.handleChange}
                error={formik.touched.degree && Boolean(formik.errors.degree)}
              >
                <MenuItem value="bachelor">Bachelor</MenuItem>
                <MenuItem value="master">Master</MenuItem>
                <MenuItem value="doctorate">Doctorate</MenuItem>
                <MenuItem value="professorship">Professorship</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <FormControl fullWidth>
              <InputLabel id="degree-label">Practice Details</InputLabel>
              <Select
                labelId="degree-label"
                id="practice_details"
                name="practice_details"
                value={formik.values.practice_details}
                label="Practice Details"
                onChange={formik.handleChange}
                error={formik.touched.practice_details && Boolean(formik.errors.practice_details)}
              >
                <MenuItem value="physician">Physician</MenuItem>
                <MenuItem value="resident">Resident</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              fullWidth
              id="occupation_discipline"
              name="occupation_discipline"
              label="Occupation & Discipline"
              value={formik.values.occupation_discipline}
              onChange={formik.handleChange}
              error={formik.touched.occupation_discipline && Boolean(formik.errors.occupation_discipline)}
              helperText={formik.touched.occupation_discipline && formik.errors.occupation_discipline}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              fullWidth
              id="business_address"
              name="business_address"
              label="Business Address"
              value={formik.values.business_address}
              onChange={formik.handleChange}
              error={formik.touched.business_address && Boolean(formik.errors.business_address)}
              helperText={formik.touched.business_address && formik.errors.business_address}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <FormControl fullWidth>
              <InputLabel id="terms-label">Terms</InputLabel>
              <Select
                labelId="terms-label"
                id="terms_accepted"
                name="terms_accepted"
                value={formik.values.terms_accepted}
                label="Is Active"
                onChange={formik.handleChange}
                error={formik.touched.terms_accepted && Boolean(formik.errors.terms_accepted)}
              >
                <MenuItem value={true}>Accepted</MenuItem>
                <MenuItem value={false}>Not Accepted</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <FormControl fullWidth>
              <InputLabel id="isActive-label">Is Active</InputLabel>
              <Select
                labelId="isActive-label"
                id="is_active"
                name="is_active"
                value={formik.values.is_active}
                label="Is Active"
                onChange={formik.handleChange}
                error={formik.touched.is_active && Boolean(formik.errors.is_active)}
              >
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>InActive</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Box mb={2}>
          <Divider sx={{ my: 3 }} />
          <Typography variant="subtitle1" gutterBottom sx={{ ml: 2 }}>Other Details</Typography>
          <Paper elevation={2} sx={{ margin: 2, padding: 2, bgcolor: 'grey.300' }}>
            <List>
              <ListItem divider>
                <ListItemText primary={<Typography variant="body1"><strong>ID:</strong> {profile.id}</Typography>} />
              </ListItem>
              <ListItem divider>
                <ListItemText primary={<Typography variant="body1"><strong>Generated ID (Device):</strong> {profile.generated_id}</Typography>} />
              </ListItem>
              <ListItem divider>
                <ListItemText primary={<Typography variant="body1"><strong>Created At:</strong> {dayjs(profile.created_at).format('DD/MM/YYYY HH:mm:ss')}</Typography>} />
              </ListItem>
              <ListItem divider>
                <ListItemText primary={<Typography variant="body1"><strong>Updated At:</strong> {dayjs(profile.updated_at).format('DD/MM/YYYY HH:mm:ss')}</Typography>} />
              </ListItem>
              <ListItem>
                <ListItemText primary={<Typography variant="body1"><strong>Last Time Updated By:</strong> {profile.updated_by === 1 ? 'SYSTEM' : `ADMIN ID (${profile.updated_by})`}</Typography>} />
              </ListItem>
            </List>
          </Paper>
          <Divider sx={{ my: 3 }} />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<ArrowBackIcon />}
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            disabled={!formik.dirty || !formik.isValid || formik.isSubmitting}
          >
            Update
          </Button>
        </Box>
      </Box>
      <LoadingOverlay open={isLoading}/>
    </Container>
  );
}