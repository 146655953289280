import React, { useContext, useEffect, useState } from 'react';
import { Box, Modal, Typography, Divider, IconButton, TextField, Button, Card, CardContent, CardActions } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import dayjs from 'dayjs';
import { fetchProfile, registerProfileForEvent } from '../../utils/http';
import { EventsManageContext } from '../../store/EventsManageContext';
import { AlertContext } from '../../store/AlertContext';


const AddProfileToEvent = ({ isOpen, onClose, eventId, setOverLayState }) => {
  const [profileId, setProfileId] = useState('');
  const [profile, setProfile] = useState(null);
  const { updateEvent } = useContext(EventsManageContext);
  const { showAlert } = useContext(AlertContext);

  useEffect(() => {
    return () => setProfile(null);
  },[]);

  const handleProfileFetch = async () => {
    try {
      const response = await fetchProfile(profileId);
      setProfile(response.data);      
    } catch (err) {
      console.log(err);
      showAlert('warning', "Couldn't fetch profile!")
    }
  };

  const handleRegisterProfile = async () => {
    try{
      if(!profile.is_active){
        showAlert('warning', 'Profile must be active!');
        return;
      }

      if(!profile.terms_accepted){
        showAlert('warning', 'Profile Terms must be accepted!');
        return;
      }
      
      setOverLayState(true);
      const response = await registerProfileForEvent(eventId, { profileId: profileId });
      updateEvent(response.data);
      onClose();
      showAlert('success', 'Profile Registered For Event!');
    }catch(err){
      console.log(err);
      showAlert('error', 'Unable to register a profile.');
    }finally{
      setOverLayState(false);
    }
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box sx={{
        position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
        width: '80vw', maxWidth: '500px', bgcolor: 'background.paper',
        p: 4, overflowY: 'auto', maxHeight: '90vh', outline: 'none'
      }}>
        <IconButton onClick={onClose} sx={{ position: 'absolute', top: 8, right: 8 }}>
          <CloseIcon />
        </IconButton>
        <Typography variant="h6" component="h2" gutterBottom>
          Add Profile To Event: ID {eventId}
        </Typography>
        <Divider sx={{ my: 2 }}/>
        <TextField
          fullWidth
          label="Profile ID"
          variant="outlined"
          value={profileId}
          onChange={(e) => setProfileId(e.target.value)}
          sx={{ mb: 2 }}
        />
        <Button variant="contained" onClick={handleProfileFetch} sx={{ mb: 2 }}>
          Get Profile
        </Button>
        {profile ? (
            <Card variant="outlined" sx={{ mb: 2 }}>
              <CardContent>
                <Typography variant="h5" component="div">
                  {profile.first_name} {profile.last_name}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  Email: {profile.email}
                </Typography>
                <Typography variant="body2">
                  ID: {profile.id}
                </Typography>
                <Typography variant="body2">
                  Active: {profile.is_active ? 'Yes' : 'No'}
                </Typography>
                <Typography variant="body2">
                  Terms Accepted: {profile.terms_accepted ? 'Yes' : 'No'}
                </Typography>
                <Typography variant="body2">
                  Created At: {dayjs(profile.created_at).format('DD/MM/YYYY')}
                </Typography>
              </CardContent>
              <CardActions sx={{ width: '100%', justifyContent: 'flex-end' }}>
                <Button size="medium" variant='outlined' color='success' onClick={handleRegisterProfile}>Register Profile</Button>
              </CardActions>
            </Card>
            )
          : (<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '232px' }}>
              <Typography variant='h6'>SEARCH A PROFILE</Typography>
          </Box>)
        }
      </Box>
    </Modal>
  );
};

export default AddProfileToEvent;
