import React, { useState, useRef, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';

function ImageCard({ imageUrl, onDelete, onPreview }) {
  const [isCopied, setIsCopied] = useState(false);
  const timeoutRef = useRef(null);

  const handleCopy = () => {
    navigator.clipboard.writeText(imageUrl);
    setIsCopied(true);
    timeoutRef.current = setTimeout(() => setIsCopied(false), 3000); 
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return (
    <Card>
      <CardMedia
        component="img"
        height="180"
        image={imageUrl}
        alt="Image"
        onClick={() => onPreview(imageUrl)}
        sx={{ cursor: 'pointer' }}
      />
      <CardActions sx={{ justifyContent: 'space-around', paddingTop: 2, paddingBottom: 2 }}>
        <Button size="small" variant='contained' color='primary' onClick={handleCopy}>
          {isCopied ? 'Copied!' : 'Copy URL'}
        </Button>
        <Button size="small" variant='contained' color='warning' onClick={() => onPreview(imageUrl)}>Preview</Button>
        <Button size="small" variant='contained' color='error' onClick={onDelete}>Delete</Button>
      </CardActions>
    </Card>
  );
}

export default ImageCard;
