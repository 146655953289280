import React, { createContext, useState } from 'react';

export const MediaContext = createContext({
  images: [],
  setImages: (images) => {},
  removeImage: (imageId) => {},
  addImage: (image) => {},
  documents: [],
  setDocuments: (documents) => {},
  removeDocument: (documentId) => {},
  addDocument: (document) => {}
});

function MediaContextProvider({ children }) {
  const [mediaState, setMediaState] = useState({ images: [], documents: []});
  
  function setImages(images){
    setMediaState((prevState) => ({ ...prevState, images }));
  }

  function addImage(image){
    setMediaState((prevState) => ({
      ...prevState,
      images: [ ...prevState.images, image ]
    }));
  }

  function removeImage(imageId){
    setMediaState((prevState) => {
      return { ...prevState, images: prevState.images.filter((img) => img.id !== imageId) };
    });
  }

  function setDocuments(documents){
    setMediaState((prevState) => ({ ...prevState, documents }));
  }

  function addDocument(document){
    setMediaState((prevState) => ({
      ...prevState,
      documents: [ ...prevState.documents, document ]
    }));
  }

  function removeDocument(documentId){
    setMediaState((prevState) => {
      return { ...prevState, documents: prevState.documents.filter((doc) => doc.id !== documentId) };
    });
  }

  const value = {
    ...mediaState,
    setImages,
    addImage,
    removeImage,
    setDocuments,
    addDocument,
    removeDocument
  };

  return (
    <MediaContext.Provider value={value}>
      {children}
    </MediaContext.Provider>
  );
}

export default MediaContextProvider;
