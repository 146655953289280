import React, { useContext, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import LibraryAddOutlinedIcon from '@mui/icons-material/LibraryAddOutlined';
import ImageCard from './ImageCard';
import ImagePreviewModal from './ImagePreviewModal';
import ImageUploadModal from './ImageUploadModal';
import LoadingOverlay from '../LoadingOverlay';
import { listImages, removeImage } from '../../utils/http';
import { MediaContext } from '../../store/MediaContext';
import { AlertContext } from '../../store/AlertContext';

const GalleryView = () => {
    const [previewModalState, setPreveiewModalState] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [previewUrl, setPreviewUrl] = useState('');
    const [imageUploadModalState, setImageUploadModalState] = useState(false);
    const [selectedImageFile, setSelectedImageFile] = useState('');
    const { setImages, addImage, removeImage: removeImageFromState, images } = useContext(MediaContext);
    const { showAlert } = useContext(AlertContext);

    useEffect(() => {
        const fetchImages = async() => {
            try{
                const result = await listImages('Event');
                setImages(result.data);
            }catch(err){
                console.log(err);
            }
        }
        fetchImages();
    },[]);

    const handlePreview = (url) => {
        setPreviewUrl(url);
        setPreveiewModalState(true);
    };

    const handleClose = () => {
        setPreveiewModalState(false);
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        //In case if user didn't choose the file
        //after clicking the button
        if(!file) return;
        const image = new Image();
        image.src = URL.createObjectURL(file);
        image.onload = () => {
          if (image.width >= 700 && image.height >= 600) {
            setSelectedImageFile(image.src);
            setImageUploadModalState(true);
          } else {
            showAlert("warning","Image resolution is too low. Please select an image larger than 700x600pxs.");
          }
        };
    };

    const handleDelete = async(imageId) => {
        try{
            setIsLoading(true);
            const result = await removeImage(imageId);
            if(result.data.url){
                removeImageFromState(imageId);
            }
            showAlert("info","Image was deleted!");
        }catch(err){ 
            console.log(err);
            showAlert("error","Unable to delete the image!");
        }finally{
            setIsLoading(false);
        }
    }

    return (
        <Container>
            <Box display="flex" alignItems="center" justifyContent="space-between" marginBottom={2}>
                <Typography variant="h4" gutterBottom>Gallery</Typography>
                <IconButton
                    color="primary"
                    onClick={() => document.getElementById('image-upload-input').click()}
                    sx={{ borderRadius: '50%' }}
                >
                    <LibraryAddOutlinedIcon fontSize="large" />
                    <input
                        id="image-upload-input"
                        type="file"
                        hidden
                        onChange={handleFileChange}
                        accept="image/*"
                    />
                </IconButton>
            </Box>
            <Divider/>
            <Grid container spacing={2} mt={2}>
                {images.map((image, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                    <ImageCard
                        imageUrl={image.url}
                        onDelete={() => handleDelete(image.id)}
                        onPreview={handlePreview}
                        />
                </Grid>
                ))}
            </Grid>
            <ImagePreviewModal isOpen={previewModalState} previewUrl={previewUrl} handleClose={handleClose}/>
            <ImageUploadModal 
                isOpen={imageUploadModalState} 
                image={selectedImageFile} 
                addImageToState={addImage} 
                setOverLayState={setIsLoading} 
                handleClose={() => setImageUploadModalState(false)}
            />
             <LoadingOverlay open={isLoading}/>
        </Container>
    );
};


export default GalleryView;